.header {
  position: fixed;
  z-index: 99;
  top: 50px;
  height: 80px;
  width: 100vw;
  background: rgba(18, 18, 18, 0.9);
  backdrop-filter: blur(5px);

  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 30px;

  .solo_logo {
    width: 106px;
    height: 60px;
    cursor: pointer;
  }
}
