.the_origin_header {
  position: fixed;
  top: 0;
  z-index: 100;

  height: 50px;
  width: 100%;
  background: #121212;

  display: flex;
  // justify-content: space-between;
  align-items: center;
  padding-left: 30px;
  gap: 80px;

  img {
    cursor: pointer;
  }

  p {
    color: rgba(255, 255, 255, 0.8);
    font-family: 'Spoqa Han Sans Neo';
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    letter-spacing: 0.28px;

    cursor: pointer;
  }
}
