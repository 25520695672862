.wrapper {
  .container {
    width: 100vw;
    height: 100vh;

    a { 
      color: white;
    }
    .background {
      width: 100vw;
      height: 100vh;
      background-color: aqua;

      background-image: linear-gradient(
          to bottom,
          rgba(0, 0, 0, 0.2),
          rgba(0, 0, 0, 0.99)
        ),
        url('../../assets/images/landing_bg1.jpg');
      background-size: cover;

      display: flex;
      flex-direction: row;
      // justify-content: space-between;
      justify-content: center;
      align-items: center;

      img {
        position: absolute;
        height: 95vh;
        // height: 50vw;
      }

      .char1 {
        left: 0;
      }

      .char2 {
        right: 0;
      }


      .center {
        width: 742px;
        // height: 10vh;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;

        .logo {
          width: 930px;
          height: 606px;
          // width: 10vw;
          // height: 10vh;
          background: radial-gradient(
            circle,
            rgba(0, 0, 0, 1) 0%,
            rgba(0, 0, 0, 0) 50%,
            rgba(0, 0, 0, 0) 100%
          );
        }
      }
      .appstore {
        // position: relative;
        position: absolute;
        // bottom: -180px;
        bottom: 0;
        width: 100vw;
        height: 300px;
        // background-color: blue;

        display: flex;
        justify-content: center;
        align-items: center;
        gap: 50px;

        background: linear-gradient(
          to bottom,
          rgba(0, 0, 0, 0),
          rgba(0, 0, 0, 0.8),
          rgba(0, 0, 0, 0.99)
        );

        img {
          width: 220px;
          height: auto;
          display: flex;
            cursor: pointer;
        }

        .img1 {
          left: 34%;
        }
  
        .img2 {
          right: 34%;
        }

        
      }
      }
    .background2 {
      width: 100vw;
      height: 100vh;
      background-image: linear-gradient(
          to bottom,
          rgba(0, 0, 0, 0.99),
          rgba(0, 0, 0, 0.4)
        ),
        url('../../assets/images/landing_bg2.png');
      background-size: cover;

  
      .textStory { 
        font-size: 45px;
      }
      .textMain {
        font-size: 18px;
        font-weight: 400
      }
      .textMt42 { 
        margin-top: 42px;
      }
      .textMt20 {
        margin-top: 20px;
      }
      .textMy{ 
        margin-top: 40px;
        margin-bottom: 40px;
      }
    }

   

  .footer {
      // position: absolute;
      // top: calc(180vw - 230px);
      width: 100vw;
      height: 230px;
      background-color: #121212;
      z-index: 10000;

      // padding: 50px;
      display: flex;
      justify-content: space-between;

      .footer_left {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: flex-start;
        gap: 12px;
        .footer_row {
          margin-left: 220px;
          display: flex;
          flex-direction: row;
          align-items: center;
          justify-content: flex-start;
          gap: 18px;

          .footer_logo {
            margin-top: 40px;
            margin-bottom: 20px;

            img {
              position: relative;
            }
          }
          .footer_text {
            color: rgba(255, 255, 255, 0.6);
            text-align: center;
            font-family: 'Spoqa Han Sans Neo';
            font-size: 16px;
            font-style: normal;
            font-weight: 400;
            line-height: normal;
          }
        }
      }

      .footer_right {
        padding-right: 220px;
        display: flex;
        gap: 40px;
        .footer_img {
          width: 220px;
          display: flex;
          align-items: center;
          cursor: pointer;
          img {
            width: 100%;
            // width: 286px;
            height: auto;
          }
        }
      }
    }
  }
  @media screen and (max-width: 500px) {
    .container {
      width: 100%;
      height: 100vh;
      a { 
        color: white;
      }
      .background {
        width: 100vw;
        height: 100vh;
        background-color: aqua;
  
        background-image: linear-gradient(
            to bottom,
            rgba(0, 0, 0, 0.2),
            rgba(0, 0, 0, 0.99)
          ),
          url('../../assets/images/landing_bg1.jpg');
        background-size: cover;
  
        display: flex;
        flex-direction: row;
        // justify-content: space-between;
        justify-content: center;
        align-items: center;
  
        img {
          width: 100vw;
          position: absolute;
          height: 95vh;
          margin-top: 40px;
        }
        .char1 {
          left: 0;
        }
  
        .char2 {
          right: 0;
        }
        .center {
          width: 100%;
          // height: 10vh;
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
          position: absolute;
          bottom: 30%;
  
          .logo {
            width: 400px;
            height: 250px;
            z-index: 1;
           
          }
        }
        .appstore {
          width: 100%;
          // background-color: blue;
          position: relative;
          top: 37%;
          display: flex;
          align-items: center;
          justify-content: 'center';
  
          img {
            width: 160px;
            height: 50px;
            display: flex;
            cursor: pointer;
          }
          .img1 {
            left: 5%;
          }
    
          .img2 {
            right: 5%;
          }
        }
      }
      .background2 {
        width: 100%;
        height: 100vh;
        background-image: linear-gradient(
            to bottom,
            rgba(0, 0, 0, 0.99),
            rgba(0, 0, 0, 0.4)
          ),
          url('../../assets/images/landing_bg2.png');
        background-size: cover;

        .textTop{ 
          padding-top: 55px; 
        }
    
        .textStory { 
          font-size: 45px;
        }
        .textMain {
          font-size: 16px;
          font-weight: 400;
          padding: 0px 20px;
        }
        .textMt42 { 
          margin-top: 32px;
        }
        .textMt20 {
          margin-top: 15px;
        }
        .textMy{ 
          margin-top: 30px;
          margin-bottom: 30px;
        }
      }
  
      .footer {
        // position: absolute;
        // top: calc(180vw - 230px);
        width: 100vw;
        height: auto;
        background-color: #121212;
        z-index: 10000;
        padding: 20px;
  
        // padding: 50px;
        display: flex;
        justify-content: space-between;
  
        .footer_left {
          display: flex;
          flex-direction: column;
          align-items: flex-start;
          justify-content: flex-start;
          gap: 12px;
          .footer_row {
            margin-left: 0px;
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: flex-start;
            gap: 18px;
  
            .footer_logo {
              margin-top: 40px;
              margin-bottom: 20px;
  
              img {
                position: relative;
              }
            }
            .footer_text {
              color: rgba(255, 255, 255, 0.6);
              text-align: center;
              font-family: 'Spoqa Han Sans Neo';
              font-size: 14px;
              font-style: normal;
              font-weight: 400;
              line-height: normal;
              white-space: 'w';
            }
          }
        }
  
      }
    }

    
  }
}

